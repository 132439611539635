import React, {useEffect} from 'react'
import Typewriter from 'typewriter-effect';
import About from '../About/About';
import Work from '../Work/Work';
import Contact from '../Contact/Contact';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../Navbar/Navbar';
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";



const Main = () => {

    useEffect (()=>{
        Aos.init({duration:2000})
        // SCENE
    const scene = new THREE.Scene();
    scene.background = null;

    // CAMERA
    const viewAngle = 70;
    const windowW = document.documentElement.clientWidth;
    const windowH = document.documentElement.clientHeight;
    const aspectRatio = windowW/windowH;
    const near = .1;
    const far = 200000;
    const camera = new THREE.PerspectiveCamera(viewAngle, aspectRatio, near, far);
    camera.position.set(0,100,400);
    camera.lookAt(scene.position);

    // RENDERER

    // const container = document.getElementById( 'canvas-container' );
    const canvas = document.getElementById( 'yuki' );
    const renderer = new THREE.WebGLRenderer({alpha: true, antialias:true, canvas:canvas});
    renderer.setSize(windowW, windowH);
    renderer.setClearColor( 0x000000, 0 ); 

    //Logo

    const group = new THREE.Group();
    scene.add(group);
    group.position.set(0,50,0);
    let c;
        const cLoad = new THREE.FontLoader().load('../Playfair.json',function(font){
           const cGeometry = new THREE.TextGeometry('C',{
                   font: font,
                   size: 120, //default is 100
                   height: 1, //depth of words
                   curveSegments: 12, //Number of points on the curves. Default is 12.
                   bevelEnabled: true, //Turn on bevel. Default is False
                   bevelThickness: 10, //How deep into text bevel goes. Default is 10.
                   bevelSize: 0.05, //How far from text outline is bevel. Default is 8.
                　 bevelSegments: 3 //Number of bevel segments. Default is 3
            }).center();

            const cMaterial = new THREE.MeshBasicMaterial({
                color:0xeae3e3,
                metalness:100,
                wireframe: true
            });

            c = new THREE.Mesh(cGeometry,cMaterial)
            group.add(c);
        });


        const logoGeometry = new THREE.CircleGeometry(100, 120);
        const logoMaterial = new THREE.MeshBasicMaterial( { color: 0x25271c, side: THREE.DoubleSide, metalness:100,} );
        const logo = new THREE.Mesh( logoGeometry, logoMaterial );
        group.add(logo);
    
        const dotGeometry = new THREE.TorusGeometry(110, 20, 16, 100);
        const dotMaterial = new THREE.MeshBasicMaterial( { color: 0x25271c, wireframe: true, metalness:100} );
        const dot = new THREE.Mesh( dotGeometry, dotMaterial );
        group.add(dot);

    
    
    // CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement);

    
    const sw = window.matchMedia("(max-width: 1000px)");
    const sh = window.matchMedia("(max-height: 500px)");
    scale(sw,sh); 
    sw.addListener(scale);

    function scale (sw, sh) {
        if (sw.matches && sh.matches)  {
            renderer.setSize(windowW*1.5, windowH*1.5);
            group.position.x-=windowW*.25
            group.position.y+=windowW*.025
        }
    }


    // ANIMATION
    function render() {

        
            // group.rotation.x -= .005
            group.rotation.y += .01
            // group.rotation.z += .005
            group.scale.x=group.scale.y=group.scale.z=1.25 

            
        

        renderer.render(scene, camera);
        requestAnimationFrame(render);
    }
    render();



    }, []);

    return (
        <div>

        <Navbar/>
        <div id="header">    
                <div id="home" className="text-center" data-aos="fade-down" data-aos-delay="50">
                    <div>
                        <video autoPlay muted playsInline loop>
                            <source src="videos/video.mp4" type="video/mp4"/>
                        </video>
                        <div>
                        <canvas id="yuki"></canvas>
                        </div>
                        <div id="blurb">
                        <img id="logo" src="images/logoSDS.png"/>
                           <h1 className="mt-0">
                            <Typewriter
                                options={{
                                    strings: ['Cora Wan'],
                                    autoStart: true,
                                    pauseFor: 5000,
                                    loop: true,
                                }}
                                />
                            </h1>
                            <h4>UX Designer, Full Stack Developer, Problem Solver & Storyteller</h4> 
                        </div>
                        
                    </div>
                </div>
        </div>
        <Work />
        <About />
        <Contact />     
            
        </div>
    )
}

export default Main

import React, {useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'; 

const About = () => {
    useEffect (()=>{
        Aos.init({duration:2000})
    }, []);

    
    return (
        <div>
            <main>
                <section className="projects" id="portfolio" data-aos="fade-down" data-aos-delay="50">
                    <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md container">
                        <h6 className="p-0 m-0">SELECTED PROJECT</h6>
                        <h1 className="p-0 m-0">ZOFFICE</h1>                            
                        <div className="mt-3">Build with love & warmth of human touch, ZOFFICE empowers remote working. The App is powered by MERN, face recognition & Socket.IO.</div>
                        <a href="/ZOFFICE"><button type="button" className="btn btn-secondary btn-lg mt-5">See credentials</button></a>
                        </div>
                        <div className="col-md"></div>
                        <div className="col-md col-md--third"></div>
                    </div>
                    </div>
                </section>
                    

                
             {/* ***********************  Project 2  ***********************  */}

             <section id="UX" className="projects text-white" data-aos="fade-down" data-aos-delay="50">
                    <div className="container mx-5 px-5">
                    <div className="row">
                        <div className="col-md container">
                        <h6 className="p-0 m-0">SELECTED PROJECT</h6>
                        <h1 className="p-0 m-0">UX Face-Off </h1>
                        <div className="mt-3">When users visit H&M or Zara's e-shops, can they be directed to the latest collection seamlessly? Check out our UX comparison's results!</div>
                        <a href="/UX"><button type="button" className="btn btn-success btn-lg mt-5">See case study</button></a>
                        </div>
                        <div className="col-md"></div>
                        <div className="col-md col-md--third"></div>
                    </div>
                    </div>
                </section>


             {/* ***********************  Project 3  ***********************  */}

             <section id="rideBuddy" className="projects" data-aos="fade-down" data-aos-delay="50">
                    <div className="container mx-5 px-5">

                    <div className="row">
                        <div className="col-md">
                        <h6 className="p-0 m-0">SELECTED PROJECT</h6>
                        <h1 className="p-0 m-0">Ride Buddy</h1>
                        <div className="mt-3">Ride Buddy re-defines daily commute, making it fun and thought-provoking. The prototype is powered by PHP, MySQL and Javascript.</div>
                        <a href="/RideBuddy"><button type="button" className="btn btn-secondary btn-lg mt-5">See credentials</button></a>
                        </div>
                        <div className="col-md"></div>
                        <div className="col-md col-md--third"></div>
                    </div>
                    </div>
                </section>                
              
        </main>

        </div>
    )
}

export default About


import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Contact from '../Contact/Contact'
import {PlayCircleFill, X, ArrowRightCircleFill} from 'react-bootstrap-icons';
import {AppIndicator} from 'react-bootstrap-icons';
import Typewriter from 'typewriter-effect';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

const ZOFFICE = () => {
    
    const [showVideo, setShowVideo] = useState(false);
    const [showCover, setShowCover] = useState(true);


    useEffect (()=>{
        Aos.init({duration:2000})
    }, []);

    return (
        <div>
        {showCover? <Navbar />:null}
        <main>
                <section className="projects" id="portfolio" data-aos="fade-up" data-aos-delay="50">
                
                    {showCover? 
                    <div className="container mx-5 px-5">
                        <h6 className="p-0 m-0">AUGMENTED REALITY </h6>
                        <h1 className="p-0 m-0">ZOFFICE</h1>
                    <div className="row">
                        <div className="col-md">
                        <div className="my-3">Build with love & warmth of human touch, ZOFFICE empowers remote working. It's powered by MERN, face recognition & Socket.IO.</div>
                        <i><PlayCircleFill onClick={()=>{
                                        setShowVideo(true)
                                        setShowCover(false)
                                        }}/></i> 
                        <div className="mt-5">
                        <small><Typewriter
                                options={{
                                    strings: ['SCROLL FOR MORE'],
                                    autoStart: true,
                                    loop: true,
                                }}
                        /></small>
                        </div>
                        </div>
                        <div className="col-md"></div>
                        <div className="col-md"></div>
                        </div>
                    </div>
                    
                    :null}

                {showVideo?
                <div>
                <i className="x--position text-secondary"><X  onClick={()=>{
                                        setShowVideo(false)
                                        setShowCover(true)
                                        }}/></i> 
                <video autoPlay controls>
                        <source src="videos/ZOFFICE.mp4" type="video/mp4"/>
                </video>
                
                </div>
                :null}

                
                </section>
                    
             {/* ***********************  Overview  ***********************  */}

             <section className="projects--details" data-aos="fade-up" data-aos-delay="50">
                    <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                            <h6 className="p-0 m-0">OVERVIEW</h6>
                            <h2 className="mt-2"><span className="text-dark">Remote working</span> will <span className="text-dark">end</span> after Covid-19</h2>
                        </div>
                        <div className="projects--details--col col-md container">
                            <div>“The pandemic has pushed employees and customers online at an unprecedented pace, creating an urgent imperative for rapid digital transformation” --- Boston Consulting Group, 2020</div><br/>
                            <div>“While a lot of those in the workforce want to have the flexibility work from home, reports show that only highly educated, well-paid minority will be allowed for hybrid models of remote work.” --- McKinsey, 2020</div><br/>
                            <div>The pandemic has revealed <span className="text-dark">a glaring gap between physical office's interface and virtual office's</span>. The ZOFFICE team sees unprecedented market opportunity to solve this global workforce problem with augmented reality. We aimed to build an improved user experience through bringing human warmth to the virtual office's interface, where you can feel the presence of your team, just as in the past.</div><br/>
                            <div><b>ZOFFICE Team</b></div>
                            <div>Cora Wan, Team Lead</div>
                            <div>Disha Shah, Chief Art Director</div>
                            <div>Fiona Ho, Chief Tech Officer</div>
                            <div>Victoria You, Chief UX Officer</div>                             
                            <div className="my-5 text-right">
                            <small><Typewriter
                                    options={{
                                        strings: ['SCROLL FOR MORE'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                            /></small>
                            </div>
                            </div>  
                        </div>
                        </div>
                </section>                          

                
             {/* ***********************  Low fidelity wireframe  ***********************  */}

            <section id="zOWF" className="projects" data-aos="fade-up" data-aos-delay="50">
            </section>


             {/* ***********************  Challenges  ***********************  */}
            
             <section className="projects--details" data-aos="fade-up" data-aos-delay="50">
             <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                                <h6 className="p-0 m-0">CHALLENGES</h6>
                                <h2 className="mt-2"><span className="text-dark">Balance</span> privacy and shared <span className="text-dark">presence</span> in the <span className="text-dark">augmented reality</span></h2>
                        </div>
                        <div className="projects--details--col col-md container">
                            <div>It is human nature that we resist change. The most common office communication interface, such as email and instant messaging systems, has been around for decades. In order to engage the modern workforce in the new normal, ZOFFICE’s interface must take a new look that not only match with users’ existing habits and expectations, but also having extra charms to appeal to them.</div><br/>
                            {/* <div>While ZOFFICE is aimed to actualise a physical office’s user interface and user experience, we are consciously aware that a shared sense of presence enabled by technologies inevitably involves capturing one’s digital footprint and imagery. Therefore, the design of ZOFFICE must strike a balance between one’s private and shared presence in the augmented reality, safeguarding an employee’s reasonable sense of privacy.</div><br/> */}
                        <div className="my-5 text-right">
                                <small><Typewriter
                                        options={{
                                            strings: ['SCROLL FOR MORE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></small>
                        </div>
                    </div>
                    </div>
                    </div>
                </section>  
                                                    
             {/* ***********************  Low fidelity wireframe  ***********************  */}

            <section id="zOFP" className="projects" data-aos="fade-up" data-aos-delay="50">
            </section>

            {/* ***********************  Strategy  ***********************  */}  
            <section className="projects--details" data-aos="fade-up" data-aos-delay="50">
            <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                                <h6 className="p-0 m-0">STRATEGY</h6>
                                <h2 className="mt-2"><span className="text-dark">Gamified</span> office floor playing to <span className="text-dark">evolutionary psychology</span></h2>
                        </div>
                        <div className="projects--details--col col-md container mt-3">
                        <div>We have disrupted traditional virtual office interface and created a gamified office floor with users' avatars either be seated at their own cubicles or in a meeting room when they are meeting with other colleagues. Meanwhile, we retain many functionalities of an office communication app, such as group chat and direct messaging, preserving users' existing comfort zone.</div><br/>
                        <div>To synchronise the real time presence of team members, we will capture a user’s face with the WebRTC technology and run the image through LUXAND face recognition API to verify a user’s online status every 15 minutes.</div><br/>
                        <div>Taking a workplace with office hours from 9am to 6pm as an example, an employee’s presence will be verified four times an hour for eight hours. If a user missed a window to be verified, they will notice their absence in the virtual office floor right away, thus they can log in again to stay online and visible to other members.</div><br/>
                        <div className="my-5 text-right">
                                <small><Typewriter
                                        options={{
                                            strings: ['SCROLL FOR MORE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></small>
                    </div>
                    </div>
                    </div>
                </div>
                </section>  
            {/* *********************** Results visual  ***********************  */}
            <section className="projects" data-aos="fade-up" data-aos-delay="50">
                
                <video autoPlay muted playsInline loop>   
                        <source src="videos/ZOFFICE1.mp4" type="video/mp4"/>
                </video>
            </section>
            {/* *********************** Results ***********************  */}
            <section className="projects--details" data-aos="fade-up" data-aos-delay="50">
            <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                                <h6 className="p-0 m-0">RESULTS</h6>
                                <h2 className="mt-2 mb-0"><span className="text-dark">Virtual</span> office with <span className="text-dark">warmth</span></h2>
                                <i><a href="https://github.com/yukyiwan/zoffice" target="_blank"
                            rel="noopener noreferrer"><AppIndicator /></a></i> 
                        </div>
                        <div className="projects--details--col col-md container mt-3">
                            <div>ZOFFICE represents an upgraded office interface that extends remote working to the modern workforce even after the global pandemic is over. Central to the design of ZOFFICE is extending human touch and warmth offered by a physical office to a virtual office. Staff will be able to see their colleagues’ avatars be seated next to them, sharing a sense of mutual presence even in a virtual office.</div><br/>
                            <div>While retaining an office communication application’s functionalities, including personal profile, audio & video streaming, group chat and direct messaging services, ZOFFICE adds value by rendering the presence of your team through augmented reality.</div><br/>
                            <div>We have built ZOFFICE 1.0 with MERN stack, WebRTC, GraphQL and Socket.IO, giving rise to a fully functioning office social network that allows staff to connect and communicate with each other with full comfort of mutual presence.</div><br/>
                            <div className="my-5 text-right">
                                <small><Typewriter
                                        options={{
                                            strings: ['SCROLL FOR MORE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></small>
                            
                    </div>
                    </div>
                    </div>
                </div>
            </section>  

            {/* *********************** Results visual  ***********************  */}
            <section id="zORS" className="projects" data-aos="fade-up" data-aos-delay="50">
            </section>

            {/* *********************** Other work  ***********************  */}
            <section id="others" className="bg-light m-0" data-aos="fade-up" data-aos-delay="50">

            <div class="container mx-3 px-3">
            <h2 className="text-left text-secondary mb-2"><Typewriter
                                        options={{
                                            strings: ['See also...'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></h2>
            <div class="row">
                <div class="col-lg mx-2 mb-5 pt-3 bg-white">
                <div><a href="/UX"><img src="images/UX.jpg" alt="..." /></a></div>   
                <div className="bg-white text-left p-3">
                        <h4 className="p-0 m-0">UX Face-Off </h4>
                        <div className="mt-3">When users visit H&M or Zara's e-shops, can they be directed to the latest collection seamlessly? Check out our UX comparison's results!  </div>
                        <div className="text-right mt-3"><a href="/UX"><button type="button" className="btn btn-secondary"><b>See case study</b></button></a></div>
                </div>
                </div>
                <div class="col-lg mx-2 mb-5 pt-3 bg-white">
                <div><a href="/RideBuddy"><img src="images/RideBuddy.jpg" alt="..." /></a></div>   
                <div className="bg-white text-left p-3">
                        <h4 className="p-0 m-0">Ridy Buddy</h4>
                        <div className="my-3">Ride Buddy re-defines daily commute, making it fun and thought-provoking. The prototype is powered by PHP, MySQL and Javascript.</div>
                        <div className="text-right mt-3"><a href="/RideBuddy"><button type="button" className="btn btn-secondary"><b>See case study</b></button></a></div>
                </div>
                </div>

                <div id="more" class="col-lg mx-2 mb-5 d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <div><a href="/ComingSoon"><i><ArrowRightCircleFill /></i></a></div>
                        <a href="/ComingSoon"><p className="text-secondary">more</p></a>
                    </div>
                </div>
            </div>
            </div>
            

            </section>
              
        </main>
        <Contact />
        </div>
    )
}

export default ZOFFICE

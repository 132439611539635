import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Contact from '../Contact/Contact'
import {PlayCircleFill, X, ArrowRightCircleFill} from 'react-bootstrap-icons';
import Typewriter from 'typewriter-effect';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

const UX = () => {
    
    const [showVideo, setShowVideo] = useState(false);
    const [showCover, setShowCover] = useState(true);


    useEffect (()=>{
        Aos.init({duration:2000})
    }, []);

    return (
        <div>
        {showCover? <Navbar />:null}
        <main>
                <section className="projects text-white" id="UX" data-aos="fade-down" data-aos-delay="50">
                
                    {showCover? 
                    <div className="container mx-5 px-5">
                    <div className="row">
                        <div className="col-md">
                        <h6 className="p-0 m-0">INTERFACE CASE STUDY</h6>
                        <h1 className="p-0 m-0">H&M</h1>
                        <h1 className="p-0 m-0">ZARA</h1>
                        <div className="my-3">When users visit H&M or Zara's e-shops, can they be directed to the latest collection? Check out our UX comparison's results!</div>
                        <i><PlayCircleFill onClick={()=>{
                                    setShowVideo(true)
                                    setShowCover(false)
                                    }}/></i> 
                        <div className="mt-5">
                        <small><Typewriter
                                options={{
                                    strings: ['SCROLL FOR MORE'],
                                    autoStart: true,
                                    loop: true,
                                }}
                        /></small>
                        </div>
                        </div>
                        <div className="col-md"></div>
                        <div className="col-md col-md--third"></div>
                        </div>
                    </div>
                    
                    :null}

                {showVideo?
                <div>
                <i className="x--position"><X  onClick={()=>{
                                        setShowVideo(false)
                                        setShowCover(true)
                                        }}/></i> 
                <video controls autoPlay>
                        <source src="videos/UX.mp4" type="video/mp4"/>
                </video>
                
                </div>
                :null}

                
                </section>
                    
             {/* ***********************  Overview  ***********************  */}

             <section className="projects--details" data-aos="fade-down" data-aos-delay="50">
                    <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                            <h6 className="p-0 m-0">OVERVIEW</h6>
                            <h2 className="mt-2">Is the e-shop's <span className="text-dark">profit margin </span>optimised by <span className="text-dark">UX design?</span></h2>
                        </div>
                        <div className="projects--details--col col-md container">
                            <div>Our case study is based on the job story of a fast fashion customer's situation, motivation and espectation. "When I go to H&M / Zara, I want to be showed to the latest collection, so I can add a few latest trending pieces to my wardrobe."</div><br/>
                            <div>We have set up a live user test to observe whether a user can move from screen to screen seamlessly to buy an outfit from the latest collection, where the highest profit margin is for any fashion retailer!</div><br/>
                            <div>The user test has yielded interesting findings and takeaways on the strength of the e-commerce platform's UI/UX and what enhancements may potentially change the game.</div><br/>
                            
                            <div><b>UX Research Team</b></div>
                            <div>Cora Wan | Disha Shah </div>
                            <div>Fiona Ho | Victoria You</div>
                        
                            
                            <div className="my-5 text-right">
                            <small><Typewriter
                                    options={{
                                        strings: ['SCROLL FOR MORE'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                            /></small>
                            </div>
                        
                        </div>


                    </div>
                    </div>
                </section>                          

                
             {/* ***********************  H&M Winning case  ***********************  */}

            <section id="HM" className="projects" data-aos="fade-down" data-aos-delay="50">
            </section>


             {/* ***********************  Challenges  ***********************  */}
            
             <section className="projects--details" data-aos="fade-down" data-aos-delay="50">
             <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                                <h6 className="p-0 m-0">FINDINGS</h6>
                                <h2 className="mt-2">Delicate <span className="text-dark">balance </span>between <span className="text-dark">UX</span> & specific sense of <span className="text-dark">aesthetics</span></h2>
                        </div>
                        <div className="projects--details--col col-md container">
                            <div>H&M’s interface design has clearly gained upper hand, in terms of getting the specific tasks done in the user test– directing our user to latest collection and facilitate payment seamlessly.</div><br/>
                            <div>In our user test, the user faced various problems reaching Zara’s latest collection, although the user can make payment effortlessly after eventually locating the latest collection.</div><br/>
                            <div>Our team found that the main factor affecting Zara’s UX performance is its high fashion, aloof and unique style that adopts miniature text description and no call-to-action banners or buttons. This contrasts to H&M’s friendly, down to earth and common e-commerce platform style.</div><br/>                        
                            <div>While H&M provides a seamless user experience, the platform is not without room of improvement. We found that providing add-to-chat function earlier, in the product listing view, can boost sales and enhance user experience significantly!</div><br/>
                    
                        <div className="my-5 text-right">
                                <small><Typewriter
                                        options={{
                                            strings: ['SCROLL FOR MORE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></small>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>  
                                                    
             {/* ***********************  Zara's UI  ***********************  */}

            <section id="ZR" className="projects" data-aos="fade-down" data-aos-delay="50">
            </section>

            {/* ***********************  Strategy  ***********************  */}  
            <section className="projects--details" data-aos="fade-down" data-aos-delay="50">
            <div className="container  mx-5 px-4">
                    <div className="row">
                        <div className="col-md">
                                <h6 className="p-0 m-0">SOLUTION</h6>
                                <h2 className="mt-2"><span className="text-dark">Connect</span> the<span className="text-dark"> dots</span></h2>
                        </div>
                        <div className="projects--details--col col-md container mt-3">
                        <div>Having unique, high-fashion aesthetics is, in Zara’s case, important for attracting customers who are willing to pay more for the brand. However, competition for attention span in the digital space is brutal.</div><br/>
                        <div>Therefore, the solution, we believe, is not to make Zara look like H&M or Gap, but making subtle improvements to drastically improve the user flow and experience such that users can be clearly and directly guided to reach and buy the latest collection.</div><br/>
                        <div>Our solution sees call-to-action appear on the images in bigger and bolder font, so that users will not miss them. Also, we have designed a "shop now" call-to-action button to connect users to the entire suite of products carried by the model in the image to encourage buying.</div><br/>
                        <div>In terms of product display, Zara is showing a number of products on a model shot. To improve clarity, our recommended product showcase will include functionalities that users can toggle between model and product views.</div>

                        <div className="my-5 text-right">
                                <small><Typewriter
                                        options={{
                                            strings: ['SCROLL TO VIEW SOLUTION'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></small>
                        </div>
                        </div>

                    </div>
                    </div>
                </section>  


            {/* *********************** Results visual  ***********************  */}
            <section id="UXRS" className="projects" data-aos="fade-down" data-aos-delay="50">
            </section>

            {/* *********************** Other work  ***********************  */}
            <section id="others" className="bg-success m-0" data-aos="fade-up" data-aos-delay="50">

            <div class="container mx-3 px-3">
            <h2 className="text-left text-secondary mb-2"><Typewriter
                                        options={{
                                            strings: ['See also...'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                /></h2>
            <div class="row">
                <div class="col-lg mx-2 mb-5 pt-3 bg-white">
                <div><a href="/ZOFFICE"><img src="images/ZOFFICE.jpg" alt="..." /></a></div>   
                <div className="bg-white text-left p-3">
                        <h4 className="p-0 m-0">ZOFFICE</h4>
                        <div className="others--blurb my-3">Build with love & warmth of human touch, ZOFFICE empowers remote working. The App is powered by MERN, face recognition & Socket.IO.
                </div>
                        <div className="text-right mt-3"><a href="/ZOFFICE"><button type="button" className="btn btn-secondary"><b>See case study</b></button></a></div>
                </div>
                </div>

                <div class="col-lg mx-2 mb-5 pt-3 bg-white">
                <div><a href="/RideBuddy"><img src="images/RideBuddy.jpg" alt="..." /></a></div>   
                <div className="bg-white text-left p-3">
                        <h4 className="p-0 m-0">Ridy Buddy</h4>
                        <div className="my-3">Ride Buddy re-defines daily commute, making it fun and thought-provoking. The prototype is powered by PHP, MySQL and Javascript.</div>
                        <div className="text-right mt-3"><a href="/RideBuddy"><button type="button" className="btn btn-secondary"><b>See case study</b></button></a></div>
                </div>
                </div>

                <div id="more" class="col-lg mx-2 mb-5 d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <div><a href="/ComingSoon"><i><ArrowRightCircleFill /></i></a></div>
                        <a href="/ComingSoon"><p className="text-secondary">more</p></a>
                    </div>
                </div>
            </div>
            </div>
            </section>
              
        </main>
        <Contact />
        </div>
    )
}

export default UX
